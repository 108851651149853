* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Open Sans', sans-serif;

  }

  .login_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

  }

.login-background{
  background: url('https://cdn.pixabay.com/photo/2024/01/27/08/22/ai-generated-8535496_960_720.png');
  /* background: url('/img/pexels-fotios-photos-1090638.jpg'); */
  background-repeat: no-repeat;
background-size: cover;
background-position: center;
}


  .form-container {
    width: 345px;
    margin: 95px auto;
    padding: 20px;
    background-color: #333333c7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 36px;
    color: #c0bfc2;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 10px;
    font-size: 18px;
  }

  input {
    padding: 4px;
    border: none;
    border-radius: 2px;
    outline: none;
    margin-bottom: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #555;
  }

  .login-button {
    padding: 10px;
    background-color: cadetblue;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.2s ease-in-out;
  }

  .login-button:hover {
    background-color: #8c00ff;
  }

 
.lo


  p {
    text-align: center;
    margin: 8px;
  }

 