/* SimilarProductModal.css */
.modal-overlay.similar {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 2000;
  }
  
  .modal-overlay.similar.active {
    display: block;
  }
  
  .modal-content.similar {
    background-color: white;
    padding: 20px;
    margin: 5% auto;
    max-width: 100%;
    max-height: 100%;
    z-index: 2001;
  }
  