/* Style for the table */
.quotation-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    height:"100%";
  }
  
  /* Style for table header */
  .quotation-table th {
    padding: 12px 15px;
    background-color: #4CAF50;
    color: white;
    text-align: left;
  }
  
  /* Style for table data cells */
  .quotation-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Style for alternate rows */
  .quotation-table .even-row {
    background-color: #f2f2f2;
  }
  

  .pagination-controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .pagination-controls button {
    padding: 5px 10px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination-controls button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  
  /* Quotation Table */
  .quotation-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .quotation-table th, .quotation-table td {
    padding: 10px;
    text-align: left;
  }
  
  .quotation-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  