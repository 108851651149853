
/* Transition for smooth hover effect on images */
.transition-imageIN {
    transition: transform 0.3s ease, border-color 0.3s ease; /* Added border color transition */
    border: 3px solid transparent; /* Initial border state */
}

/* Hover effect on image */
.transition-imageIN:hover {
    transform: scale(1.1); /* Slight zoom on hover */
    border-color: #5ca8b9; /* Change border color on hover */
}

/* Brand name hover effect */
.nav-linked {
    transition: color 0.3s ease, transform 0.3s ease, font-weight 0.3s ease; /* Added font-weight transition */
    color: black; /* Initial color */
    font-weight: 400; /* Normal font weight by default */
}

/* Hover effect on brand name */
.nav-linked:hover {
    color: #0d778e; /* Change brand name color on hover */
    transform: scale(1.2); /* Increase font size slightly */
    font-weight: 600; /* Increase font weight on hover */
}

/* Optional: Remove the font-weight override for <b> */
.nav-linked b {
    font-weight: inherit; /* Ensure that the <b> element does not override the font weight */
}
