@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Oleo+Script:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Comparison.css */
.comparison-container {
    /* overflow: auto; */
    /* max-width: 100%;
    max-height: 450px; Adjust height as needed */
  }
  body{
    background: aliceblue;
    
  }
  .category-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
    border-bottom: 2px solid #ccc; /* Optional: Adds a line under the category name */
    padding-bottom: 10px; /* Adds spacing below the category name */
  }
  
  
  .comparison-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .comparison-table th,
  .comparison-table td {
    border: 1px solid #ddd;
    /* padding: 8px; */
    text-align: center;
  }
  
  .comparison-table th {
    background-color: #f2f2f2;
  }
  
  .product-image {
    width: 150px;
    height: 150px;
  }
  
  /* To make the table horizontally scrollable */
  .comparison-container {
    display: block;
    overflow-x: auto;
  }
  
  .comparison-table {
    min-width: 800px; /* Adjust based on the number of columns */
  }

  td div {
    margin-bottom: 5px; /* Adds space between each product detail */
  }
  td{
    color:"black"
  }

  
  /*-----------------------------------------------
   card css strarts  
   ------------------------------------------------*/

.card{
  height: 281px;
  width: 195px;
  border: 2px solid rgb(116, 116, 116);
  box-shadow: 2px 3px 4px gray;
  transition: transform 0.9s ease;
  /* background: lavender; */
}
.card:hover{
  transform: scale(1.05);
 
}
.card p{
  margin-bottom: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
}
.card h5{
  font-family: "DM Serif Text", serif;
  font-size: 15px;

}
.card img{
  height: 68px;
  width: 67px;
margin: 20px auto;
border-radius: 50%;
}

.pr-box{
  background: rgb(255 161 106);
  border-radius: 20px;
}
.card-title{
  margin-bottom: 10px;
  white-space: nowrap;      /* Prevent text from wrapping to the next line */
  overflow: hidden;         /* Hide the overflowing content */
  text-overflow: ellipsis;  /* Add ellipsis (...) for overflowing text */
  cursor: pointer;
}
.card-body{
  /* background: #ffe2c9; */
  border-radius: 9px
}
.comparison-container{
  scrollbar-width: none;
}
.pr-box{
  width: 286px;
  height: 29px;
}

.card-body span, strong{
  float: right;
}

.comparison-container h3{
  font-size: medium;
}




.comparison-container .sticky-brand-name{
  text-align: center;
  color: #000000;
  position: sticky;
  top: 0;
  background-color: white; /* Ensure background is white so content underneath doesn't overlap */
  z-index: 10; /* Ensures it stays above other content */
  padding: 10px 0; /* Adds some padding for better spacing */
}
.brand-container{
  position: relative;
}





@media (min-width: 1200px) {

  .sm_brand-name{
display: none;
  }
  .lg_brand-name{
    font-size: 15px;
            }
}

@media (min-width: 992px) {
  .sm_brand-name{
    display: none;
      }
      .lg_brand-name{
        font-size: 15px;
                }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .sm_brand-name{
    display: none;
      }
      .lg_brand-name{
      font-size: 12px;
              }
}

/* For small screens (smartphones) */

  @media (max-width: 767px) {
 
    .sm_brand-name{
      display: block;
        }
        .lg_brand-name{
  display: none;
        }
  }








  .Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 35px;
    height: 35px;
    border: none;
    /* border-radius: 50%; */
    cursor: pointer;
    position: fixed; /* Position it fixed on the screen */
    bottom: 160px; /* Distance from the bottom of the screen */
    right: 20px; /* Distance from the right of the screen */
    overflow: hidden;
    transition-duration: .8s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: rgb(4, 88, 128);
  }
  
  /* plus sign */
  .sign {
    width: 100%;
    transition-duration: .8s;
    display: flex;
    height: 61%;
    align-items: center;
    color: white;
    justify-content: center;
  }
  
  .sign svg {
    width: 17px;
  }
  
  .sign svg path {
    fill: white;
  }
  
  /* text */
  .Btn .text {
    position: absolute;
    width: 0%;
    left: 0%;
    opacity: 0;
    bottom: 0;
    color: white;
    font-size: 15px;
    font-weight: 200;
    transition-duration: 1s;
  }
  
  /* hover effect on button width */
  .Btn:hover {
    width: 300px;
    /* border-radius: 40px; */
    transition-duration: .8s;
  }
  
  .Btn:hover .sign {
    width: 12%;
    transition-duration: .8s;
    padding-left: 5px;
  }
  
  /* hover effect button's text */
  .Btn:hover .text {
    opacity: 1;
    width: 100%;
    transition-duration: .1s;
    padding-right: 10px;
    bottom: 0;
  }
  
  /* button click effect */
  .Btn:active {
    transform: translate(2px, 2px);
  }
  



/* Add button */
.BtnAdd{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  height: 35px;
  border: none;
  /* border-radius: 50%; */
  cursor: pointer;
  position: fixed; /* Position it fixed on the screen */
  bottom: 100px; /* Distance from the bottom of the screen */
  right: 20px; /* Distance from the right of the screen */
  overflow: hidden;
  transition-duration: .8s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: #FFC300;
}
/* text */
.BtnAdd .text {
  position: absolute;
  width: 0%;
  left: 0%;
  opacity: 0;
  bottom: 0;
  color: white;
  font-size: 15px;
  font-weight: 200;
  transition-duration: 1s;
}

/* hover effect on button width */
.BtnAdd:hover {
  width: 300px;
  /* border-radius: 40px; */
  transition-duration: .8s;
}

.BtnAdd:hover .sign {
  width: 12%;
  transition-duration: .8s;
  padding-left: 5px;
}

/* hover effect button's text */
.BtnAdd:hover .text {
  opacity: 1;
  width: 100%;
  transition-duration: .1s;
  padding-right: 10px;
  bottom: 0;
}

/* button click effect */
.BtnAdd:active {
  transform: translate(2px, 2px);
}




/* BtnDiff button */
.BtnDiff{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  height: 35px;
  border: none;
  /* border-radius: 50%; */
  cursor: pointer;
  position: fixed; /* Position it fixed on the screen */
  bottom: 220px; /* Distance from the bottom of the screen */
  right: 20px; /* Distance from the right of the screen */
  overflow: hidden;
  transition-duration: .8s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: #c0086d ;
}
/* text */
.BtnDiff .text {
  position: absolute;
  width: 0%;
  left: 0%;
  opacity: 0;
  bottom: 0;
  color: white;
  font-size: 15px;
  font-weight: 200;
  transition-duration: 1s;
}

/* hover effect on button width */
.BtnDiff:hover {
  width: 300px;
  /* border-radius: 40px; */
  transition-duration: .8s;
}

.BtnDiff:hover .sign {
  width: 12%;
  transition-duration: .8s;
  padding-left: 5px;
}

/* hover effect button's text */
.BtnDiff:hover .text {
  opacity: 1;
  width: 100%;
  transition-duration: .1s;
  padding-right: 10px;
  bottom: 0;
}

/* button click effect */
.BtnDiff:active {
  transform: translate(2px, 2px);
}



/* Clear button */

  .clr-Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 35px;
    height: 35px;
    border: none;
    /* border-radius: 50%; */
    cursor: pointer;
    position: fixed; /* Position it fixed on the screen */
    bottom: 40px; /* Distance from the bottom of the screen */
    right: 20px; /* Distance from the right of the screen */
    overflow: hidden;
    transition-duration: .8s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: rgb(255, 123, 0);
  }
  
  /* plus sign */
  .clr-btn .sign {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    height: 61%;
    align-items: center;
    justify-content: center;
  }
  
  .clr-btn .sign svg {
    width: 17px;
  }
  
  .clr-btn .sign svg path {
    fill: white;
  }
  
  /* text */
  .clr-Btn .text {
    position: absolute;
    width: 0%;
    left: 0%;
    opacity: 0;
    bottom: 0;
    color: white;
    font-size: 15px;
    font-weight: 200;
    transition-duration: 1s;
  }
  
  /* hover effect on button width */
  .clr-Btn:hover {
    width: 300px;
    /* border-radius: 40px; */
    transition-duration: .8s;
  }
  
  .clr-Btn:hover .sign {
    width: 12%;
    transition-duration: .8s;
    padding-left: 5px;
  }
  
  /* hover effect button's text */
  .clr-Btn:hover .text {
    opacity: 1;
    width: 100%;
    transition-duration: .1s;
    padding-right: 10px;
    bottom: 0;
  }
  
  /* button click effect */
  .clr-Btn:active {
    transform: translate(2px, 2px);
  }
  
  /* ---------------------------------
alt-services css
 -----------------------------------*/ 

 .alt-services .img {
  border-radius: 8px;
  overflow: hidden;
}

.alt-services .img img {
  transition: 0.6s;
}

.alt-services .details {
  background: #ffffff87;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  padding: 30px;
  transition: all ease-in-out 0.3s;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.1);
}

.alt-services .details h3 {
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
  color: black;
  transition: ease-in-out 0.3s;
}

.alt-services .details p {
  color: color-mix(in srgb, var(--default-color), transparent 10%);
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}


.alt-services .service-item:hover .img img {
  transform: scale(1.2);
}




