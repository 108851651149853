/* ProductModal.css */

/* Overlay background for the modal */
.modal-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050; /* Ensure modal overlay is above all content */
    overflow: hidden; /* Prevent scroll when modal is open */
  }
  
  /* Modal content box */
  .modal-content {
    margin: 10% auto; /* Adjust this if you want to further tweak the vertical position */
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 60%;
    position: relative;
    z-index: 1060; /* Ensure modal content is above overlay */
    max-height: 80vh; /* Prevent modal from being too large */
    overflow-y: auto; /* Allow scroll within the modal if content overflows */
  
    /* To move the modal slightly up */
    top: 0%; /* Adjust this value to move the modal higher or lower */
    transform: translateY(-10%); /* Shift the modal up by 10% of its own height */
  }
  
  /* Close button in the top right corner */
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Image styling for the modal */
  .modal-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  /* Button styling */
.modal-overlay .btn-close {
    margin-top: 20px;
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .btn-close:hover {
    background-color: #5a6268;
  }
  
  .product-info {
    display: flex;
    margin-bottom: 10px; /* Ensures consistent space between each label-value pair */
    font-size: 14px; /* Consistent font size */
  }
  
  .product-info .label {
    font-weight: bold;
    margin-right: 10px; /* Space between the label and the value */
  }
  
  .product-info .value {
    flex-grow: 1; /* Allow value to take up remaining space */
    margin-left: 10px;
  }
  