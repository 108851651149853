/* Style for the navbar links */
.nav-link {
  color:rgb(255, 255, 255) ; /* Default link color */
  text-decoration: none; /* Remove underline */
  padding: 10px 25px; /* Padding around the link */
  font-weight: normal; /* Normal weight by default */
  display: block; /* Make the link fill the parent <li> */
  border-radius: 4px; /* Rounded corners for hover effect */
  transition: color 0.3s, background-color 0.3s; /* Smooth transition for hover effect */
  position: relative;
}

/* Style for the active link */
.nav-link.active {
  color: #119ac4; /* Active link color */
  background-color: white; /* Active background color */
  font-weight: bold; /* Bold text for active link */
  content: '';
  width: 0;
  height: 3px;
  background: #ff004f;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
}

/* Hover effect */
.nav-link:hover {
  color: rgb(255, 115, 0); 
  /* background-color: white; Background color on hover */
  text-decoration: none; /* Ensure no underline on hover */
}

/* Transition effects for the images */
.transition-image {
  border: 2px solid transparent; /* Initial border style */
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; /* Add transform for scaling effect */
  border-radius: 5px; /* Optional: rounded corners */
}

/* Scale effect on hover */
.transition-image:hover {
  /* border: 2px solid #ff004f; Border color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  transform: scale(1.25); /* Slightly scale the image on hover */
}

.navbar{
  /* background: #14141462; */
  background: black;
    position: fixed;
    width: 100%;
    z-index: 10000;
    color: white;
}
.nav-link:focus
{
color:rgb(255, 115, 0);
}