.feature-icon {
    font-size: 2rem; /* Adjust icon size */
    color: #e98112;
}
.feature-text {
    color: #0d98a3;
    text-align: center; /* Center text */
}
.feature-container {
    flex-wrap: wrap; /* Allow wrapping */
}
.feature-col {
    text-align: center; /* Center content */
    padding: 1rem; /* Add some padding */
}
@media (max-width: 767.98px) {
    .feature-col {
        flex: 1 0 100%; /* Stack columns vertically on small screens */
        margin-bottom: 1rem; /* Space between stacked columns */
    }
}

