@import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Bigshot+One&family=DM+Serif+Text:ital@0;1&family=Niconne&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.fade-in-text {
    font-family: Arial;
    /* font-size: 150px; */
    animation: fadeIn 7s;
    text-align: center;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .carousel-inner .carousel-item img {
    width: 100%;  /* Make the image take up the full width of the container */
    height: 100vh; /* Maintain the aspect ratio of the image */
     /* Ensure the image covers the container without distortion */
    filter:brightness(0.9)
  }

  .carousel-caption{
    height: 64%;
    width: 80%;
    text-align: start;
    left: 10%;

  }
  .carousel-caption h5{
   font-size: 80px;
   color:rgb(255, 255, 255);
   font-family: "Volkhov", serif;
   font-weight: 800;

  }



  